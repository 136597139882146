import moment from "moment";

export const currencyFormat = (value: number) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GHS",
    minimumFractionDigits: 2
  }).format(value);
};

export const dateFormat = (date: string, format?: string) => {
  return moment(date).format(format ?? "YYYY-MM-DD");
};
