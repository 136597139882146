
































































































































































import DialogMixins from "@/mixins/DialogMixins";
import WidgetMixins from "@/mixins/WidgetMixins";
import { IPaginate, ITransactionHistory } from "@/types/types";
import { loadWidget } from "@/utils/helpers";
import Component, { mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import { currencyFormat, dateFormat } from "@/filters/currencyFormat";
import { Watch } from "vue-property-decorator";

const paymentDetailsModule = namespace("paymentDetails");

@Component({
  name: "History",
  components: {
    ToolBar: loadWidget("widgets/ToolBar"),
    InternetConnection: loadWidget("pages/InternetConnection"),
    SnackBar: loadWidget("widgets/SnackBar")
  },
  filters: {
    currencyFormat,
    dateFormat
  }
})
export default class History extends mixins(WidgetMixins, DialogMixins) {
  @paymentDetailsModule.Getter("getAllTransactionHistory")
  transfers!: ITransactionHistory & { status: string };
  @paymentDetailsModule.Getter("getPagination") paginatedObj!: Pick<
    IPaginate,
    "itemsPerPage" | "total" | "page"
  > & { isPaginated: boolean };

  pagination: Pick<IPaginate, "itemsPerPage" | "total" | "page"> & {
    isPaginated: boolean;
  } = {
    itemsPerPage: 0,
    total: 0,
    page: 1,
    isPaginated: true
  };

  options: any = {};
  itemsPerPageOptions: number[] = [15, 20, 30, 50, 100];

  search: string = "";

  headers: Array<{ text: string; value: string; sortable?: boolean }> = [
    {
      text: "Date",
      value: "createdAt"
    },
    {
      text: "Ref.",
      value: "reference"
    },
    {
      text: "Acc. Number",
      value: "accountNumber"
    },
    {
      text: "Package",
      value: "package"
    },
    {
      text: "Amount",
      value: "totalAmount"
    },
    {
      text: "Status",
      value: "paymentStatus"
    },
    {
      text: "Actions",
      value: "actions"
    }
  ];

  transfer_code: string = "";
  userId: string = "";
  transferId: string = "";

  @Watch("paginatedObj")
  onPaginationChange(
    payload: Pick<IPaginate, "itemsPerPage" | "total" | "page"> & {
      isPaginated: boolean;
    }
  ) {
    this.pagination = { ...payload };
  }

  @Watch("options")
  moveToNext(payload: IPaginate) {
    const { itemsPerPage, page } = payload;
    const query = `?page=${page}&size=${itemsPerPage}`;
    this.$store.dispatch("paymentDetails/transactionList", query);
  }

  getTransactions() {
    const query = `?page=1&size=15`;
    this.$store.dispatch("paymentDetails/transactionList", query);
  }

  checkPaymentStatus(id: string) {
    const query = `?page=${this.options?.page ?? 1}&size=${this.options?.itemsPerPage ?? 15}`;
    this.$store.dispatch("paymentDetails/paymentStatus", { id, query });
  }

  created(): void {
    this.getTransactions();
  }
}
